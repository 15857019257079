import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "centering",
  style: {"width":"100vh","height":"100vh"}
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  class: "posFi pointer",
  style: {"right":"90px","top":"18px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueFinalModal = _resolveComponent("VueFinalModal")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_VueFinalModal, {
    "modal-id": "ImagePreviewModal",
    "content-class": "noscroll",
    "overlay-style": "border: 0; overflow: visible;background: rgba(255,255,255,0), line-height:100%;",
    onBeforeOpen: _ctx.openModal,
    onBeforeClose: _ctx.fadeOutAdModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.file)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.file.image_url,
              class: _normalizeClass(`centered previewedImage`),
              style: {"max-width":"90vh","max-height":"90vh"}
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _withDirectives(_createElementVNode("img", {
        src: `/assets/icons/dismiss_grey_dgrey`,
        width: "60",
        height: "60",
        class: "pointer posFi",
        style: {"top":"5px","right":"5px"},
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dismiss && _ctx.dismiss(...args)))
      }, null, 512), [
        [_directive_tooltip, `閉じる`]
      ]),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("img", {
          src: "/assets/icons/download",
          width: "32",
          height: "35",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.downloadFile && _ctx.downloadFile(...args)))
        }, null, 512), [
          [_directive_tooltip, `ダウンロード`]
        ])
      ])
    ]),
    _: 1
  }, 8, ["onBeforeOpen", "onBeforeClose"]))
}